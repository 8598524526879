import type { FormEvent, FunctionComponent } from 'react';
import React, { useEffect, useState } from 'react';
import type { IWithGoogleReCaptchaProps } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';

import Alert from 'src/components/ui/Alert';
import Form from 'src/components/ui/Form';
import type { FormControlElement } from 'src/components/ui/Form/components/Control';
import InputGroup from 'src/components/ui/InputGroup';
import Modal from 'src/components/ui/Modal/Modal';

import { ButtonPrimary, Title3 } from '../../index';
import style from './NewsletterModal.module.scss';

import { sendDataToNewsletterApi } from 'src/services/newsletter/sendDataToNewsletterApi';

import { UNKNOWN_ERROR } from 'src/utils/Message.Utils';
import { usePageType } from 'src/utils/ReactUtils';
import { isRequired, isInvalidFormat } from 'src/utils/ValidationMessage.Utils';
import { validInputPattern } from 'src/utils/ValidationUtils';

type NewsletterModalProps = {
  isShow: boolean;
  onHide: () => void;
  initialEmail?: string;
} & Partial<IWithGoogleReCaptchaProps>;

export const NewsletterModal: FunctionComponent<NewsletterModalProps> = ({
  isShow,
  onHide,
  initialEmail,
  googleReCaptchaProps,
}) => {
  type apiResponseContact = 'initial' | 'success' | 'error';
  const [formValidation, setFormValidation] = useState(false);
  const [apiResponseNewsletterSubmit, setApiResponseNewsletterSubmit] =
    useState<apiResponseContact>('initial');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(initialEmail || '');

  const pageType = usePageType();

  const gtmClickEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: 'newsletter',
        eventAction: 'inscription newsletter',
        eventLabel: '/',
        pageType,
      },
    });
  };

  useEffect(() => {
    if (apiResponseNewsletterSubmit !== 'initial') {
      setTimeout(onHide, 2000);
    }
  }, [initialEmail, onHide, apiResponseNewsletterSubmit]);

  const checkRecaptchaAndSendData = async () => {
    if (googleReCaptchaProps !== undefined) {
      const recaptchaToken = await googleReCaptchaProps?.executeRecaptcha?.(
        'contact_garage',
      );
      if (!recaptchaToken) {
        setApiResponseNewsletterSubmit('error');
        return;
      }
    }
    try {
      await sendDataToNewsletterApi({
        email,
        firstName,
        lastName,
      });
      setApiResponseNewsletterSubmit('success');
    } catch (error) {
      setApiResponseNewsletterSubmit('error');
    }
  };
  const contactFormSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!event.currentTarget.checkValidity()) {
      event.stopPropagation();
      setFormValidation(true);
      return;
    }
    checkRecaptchaAndSendData();
  };
  const getMessageAfterSubmitForm = () => {
    return apiResponseNewsletterSubmit === 'success' ? (
      <Alert className={style.vehicleAlert} variant="success">
        Vous êtes inscrit sur la liste des newsletters avec succès.
      </Alert>
    ) : (
      <Alert className={style.vehicleAlert} variant="danger">
        {UNKNOWN_ERROR}
      </Alert>
    );
  };
  return (
    <Modal isOpen={isShow} onClose={onHide} className={style.newsletterModal}>
      <div className={style.newsletterModalHeader}>
        <div className={style.newsletterModalTitle}>
          <Title3>
            <h3>Newsletter AD</h3>
          </Title3>
        </div>
        <button
          onClick={onHide}
          aria-label="close"
          type="button"
          className={style.close}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className={style.newsletterModalBody}>
        {apiResponseNewsletterSubmit !== 'initial' ? (
          getMessageAfterSubmitForm()
        ) : (
          <Form
            noValidate
            validated={formValidation}
            onSubmit={contactFormSubmitHandler}
          >
            <Form.Group controlId="validationFirstName">
              <Form.Label className={style.newsletterModalFormRequired}>
                Nom
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Nom*"
                  pattern={validInputPattern}
                  required
                  value={firstName}
                  onChange={(e) => {
                    const target = e.target as FormControlElement;

                    setFirstName(target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {isRequired('Nom')}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="validationLastName">
              <Form.Label className={style.newsletterModalFormRequired}>
                Prénom
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Prénom*"
                  pattern={validInputPattern}
                  required
                  value={lastName}
                  onChange={(e) => {
                    const target = e.target as FormControlElement;

                    setLastName(target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {isRequired('Prénom')}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="validationEmail">
              <Form.Label className={style.newsletterModalFormRequired}>
                Email
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="email"
                  placeholder="Email*"
                  required
                  value={email}
                  onChange={(e) => {
                    const target = e.target as FormControlElement;

                    setEmail(target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {email ? isInvalidFormat('Email') : isRequired('Email')}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <ButtonPrimary
              type={'submit'}
              onClick={gtmClickEvent}
              className={style.newsletterModalBtn}
            >
              {"Je m'abonne"}
            </ButtonPrimary>
          </Form>
        )}
      </div>
    </Modal>
  );
};
